var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav",class:[{
    bg:_vm.bg,
},_vm.type]},[_c('div',{staticClass:"_wrap"},[_c('div',{staticClass:"logo _link",on:{"click":function($event){return _vm.routerPush('Index')}}},[_c('img',{staticClass:"img-a",attrs:{"src":require("@/assets/img/logo-a.png"),"alt":""}}),_c('img',{staticClass:"img-b",attrs:{"src":require("@/assets/img/logo-b.png"),"alt":""}})]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"item _link",class:{
            act:_vm.$route.name=='Index'
        },on:{"click":function($event){return _vm.routerPush('Index')}}},[_vm._v(" 首页 ")]),_c('div',{staticClass:"item _link",class:{
            act:_vm.$route.name=='Programme'||_vm.$route.name=='ProgrammeDetails'
        },on:{"click":function($event){return _vm.routerPush('Programme')}}},[_vm._v(" 解决方案 ")]),_c('div',{staticClass:"item _link",class:{
            act:_vm.$route.name=='Information'||_vm.$route.name=='InformationDetails'
        },on:{"click":function($event){return _vm.routerPush('Information')}}},[_vm._v(" 资讯 ")])]),_c('div',{staticClass:"btn-box"},[_c('div',{staticClass:"btn _link act",on:{"click":function($event){return _vm.openFun('https://www.tianjingdt.com/')}}},[_vm._v(" 天镜DT数字孪生引擎 ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }